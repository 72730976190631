import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Accordion, Alert, Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Select from 'react-select'
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

import { ArrowClockwise } from 'react-bootstrap-icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import fieldOfInterestOptions from "../../jsonConstants/ADTInterestField.json"
import triggerTypeOptions from "../../jsonConstants/ADTTriggerOptions.json"
import './ADTFalloutDashboard.css';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function downloadIcon() {
    return <SaveAlt style={{ color: "#8A8B8D", fontSize: "30px" }} />
};

const ADTFalloutDashboard = () => {

    const idToken = useSelector((state) => state.tokens.idToken);

    //for download checkbox color
    const theme =
        createTheme({
            palette: {
                secondary: {
                    main: '#23B2D2'
                }
            },

        });

    const [controlOptions, setControlOptions] = useState(
        {
            'facility': [],
            'triggerTypes': [],
            'startDate': new Date().toISOString().split("T")[0],
            'endDate': new Date().toISOString().split("T")[0],
            'fieldOne': '',
            'fieldTwo': '',
            'conformanceValueOne': '',
            'conformanceValueTwo': '',
            'numMessages': 30
        }
    );

    const [tableData, setTableData] = useState([]);
    const [facilityNames, setFacilityNames] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        //retrieve the values for the Facility/Group drop down
        retrieveFacilities();
    }, [])

    /**
     * Handles changes to the Control values
     * @param e 
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setControlOptions({ ...controlOptions, [name]: value });
    }

    /**
     * Handles changes to the Trigger Type control
     * @param input 
     */
    const handleSelectChange = (input) => {
        let triggers = [];
        input.map(item => {
            if (item.value === "All") {
                triggers = ['A01', 'A02', 'A03', 'A04', 'A06', 'A08', 'A09', 'A10', 'A15', 'A16', 'A21', 'A26', 'A28', 'A31', 'A38', 'A40', 'A44'];
            }
            else {
                triggers.push(item.value)
            }
        }
        );
        setControlOptions({ ...controlOptions, triggerTypes: triggers });
    }

    /**
     * Handles conversion for the Hour Received by HIN column
     * @param hourInt - hour value 1-24 to be converted
     * @returns date object
     */
    const hourToTime = (hourInt) => {
        let hourStr = hourInt.toString() + ":00";
        if (hourInt < 12) {
            hourStr = "0" + hourStr;
        }
        let options = { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
        return new Date('1970-01-01T' + hourStr + 'Z').toLocaleTimeString('en-US', options);
    }

    /**
     * Takes an array of files, creates a zip folder, and downloads
     * @param parsedResult - the message files to be downloaded
     * @param messageIds - the message IDs affiliated with each file in parsedResult for file naming
     */
    const generateZip = (parsedResult, messageIds) => {

        //set up zip
        let today = new Date().toISOString().split("T")[0];
        let zipName = 'Non-Conformant ADT Messages-' + today + '.zip'
        let zip = new JSZip();

        //loop through returned messages and create files
        for (let i = 0; i < parsedResult.length; i++) {
            let fileName = messageIds[i] + '.hl7';
            if (parsedResult[i] === "error retrieving ADT file" || parsedResult[i] === "unauthorized") {
                zip.file(fileName, parsedResult[i]);
            }
            else {
                zip.file(fileName, parsedResult[i].data);
            }
        }

        //download zip
        try {
            zip.generateAsync({ type: 'blob' })
                .then(function (content) {
                    FileSaver.saveAs(content, zipName);
                });
        }
        catch (err) {
            console.log("error downloading " + err);
        }
    }

    /**
     * Constructs the s3 path associated with an ADT 
     * @param messageData - message data associated with a row that is trying to be downloaded
     * @returns - a string of the s3 file path where the ADT can be located
     */
    const constructS3Url = (messageData) => {
        let dateString = messageData.receipt_date.replaceAll("-", "/");
        let hourString = messageData.receipt_hour.toString();

        // add leading zero for single digits
        if (hourString.length < 2) {
            hourString = "0" + hourString;
        }

        return `${process.env.REACT_APP_ADT_S3_BUCKET_PATH}` + dateString + "/" + hourString + "/" + messageData.message_control_id + "_" + messageData.hin_message_uuid + ".hl7";
    }

    /**
     * On download click: calls getFileFromS3 to return all selected ADTs, zips the returned files, and downloads
     * @param event 
     * @param data 
     */
    const downloadFiles = async (event, data) => {

        //activate screen overlay until download is complete
        setIsDownloading(true);

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        //grab all urls for selected rows and messageIDs to be used in naming the files
        let s3Urls = [];
        let messageIds = [];
        data.forEach(element => {
            let url = constructS3Url(element);
            s3Urls.push(url);
            messageIds.push(element.message_control_id);
        });

        let parsedResult;
        let callURL = `${process.env.REACT_APP_CONFORMANCE_GET_FILE_URL}/?type=adt`;

        //if selected messages exceed 60, split into two calls
        if (s3Urls.length > 60) {
            let firstHalf = s3Urls.slice(0, 60);
            let secondHalf = s3Urls.slice(60);

            let firstRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: firstHalf
            };

            let secondRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: secondHalf
            };

            try {
                //make both calls
                const [firstHalf, secondHalf] = await Promise.all([
                    fetch(callURL, firstRequestOptions),
                    fetch(callURL, secondRequestOptions)
                ]);

                const firstMessages = await firstHalf.json();
                const secondMessages = await secondHalf.json();

                //combine the returned messages
                parsedResult = firstMessages.message.concat(secondMessages.message);

                generateZip(parsedResult, messageIds);
                setIsDownloading(false);
            }
            catch (err) {
                console.log(err);
            }

        }

        //if less than 60 messages selected, make a single call
        else {

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: s3Urls
            };

            fetch(callURL, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    parsedResult = JSON.parse(result);
                    generateZip(parsedResult.message, messageIds);
                    setIsDownloading(false);
                })

        }
    }

    /**
     * Retrieves the groups / facility that are associated with a user's conformance groups on their ID token
     */
    const retrieveFacilities = async () => {

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        let queryFacilitiesEndpoint = `${process.env.REACT_APP_CONFORMANCE_APIS}/retrieve-facilities?name=adt`;

        let requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        let parsedResult;

        fetch(queryFacilitiesEndpoint, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                parsedResult = JSON.parse(result);

                let facilityOids = [];
                // list to determine if a facility has been added yet to avoid duplicates in drop down
                let facilityNames = [];
                parsedResult.forEach((group, _index) => {
                    group.Facilities.forEach((facility, _index) => {
                        if (facility.FacilityName !== "" && !facilityNames.includes(facility.FacilityName)) {
                            facilityOids.push({ "value": facility.Oids, "label": facility.FacilityName });
                            facilityNames.push(facility.FacilityName);
                        }
                    })
                })
                setFacilityNames(facilityOids);
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
            })
    }

    /**
     * Retrieves the ADTs that meet the chosen filter criteria and sets the table data
     */
    const retrieveADTs = async () => {

        let myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', idToken);

        let queryADTEndpoint = `${process.env.REACT_APP_CONFORMANCE_APIS}/adt/query`;
        let queryBody = {
            "facilities": controlOptions.facility.split(","),
            "startDate": controlOptions.startDate,
            "endDate": controlOptions.endDate,
            "triggerTypes": controlOptions.triggerTypes,
            "conformanceValues": {
                [controlOptions.fieldOne]: parseInt(controlOptions.conformanceValueOne),
                [controlOptions.fieldTwo]: parseInt(controlOptions.conformanceValueTwo)
            },
            "limit": controlOptions.numMessages
        };

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(queryBody)
        };

        fetch(queryADTEndpoint, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let parsedResult = JSON.parse(result);
                setTableData(parsedResult);
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
            })

    }

    /**
     * Updates the message table if all required filters are populated
     */
    const refreshTable = () => {
        //check that all necessary controls are populated
        if (controlOptions.facility !== "" && controlOptions.startDate !== "" && controlOptions.endDate !== "" && controlOptions.triggerTypes.length > 0 && ((controlOptions.fieldOne !== "" && controlOptions.conformanceValueOne !== "") || (controlOptions.fieldTwo !== "" && controlOptions.conformanceValueTwo !== ""))) {
            retrieveADTs();
        }
    }

    /**
     * Displays a message to the user when their chosen number is greater than available messages
     */
    useEffect(() => {
        if (tableData.length > 0 && tableData.length < parseInt(controlOptions.numMessages, 10)) {
            setShowAlert(true);
        }
        else {
            setShowAlert(false);
        }
    }, [tableData, controlOptions.numMessages])

    /**
     * Calls refresh on the table when filter controls are updated
     */
    useEffect(() => {
        refreshTable();
    }, [controlOptions])

    return (
        <>
            <LoadingOverlay
                active={isDownloading}
                spinner
                text='Downloading selected ADTs...'>
                {showAlert && (
                    <Alert id="num-message-alert">You chose {controlOptions.numMessages} messages but only {tableData.length} exist. All available messages will be shown in the table below.</Alert>
                )}
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Controls</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col sm={{ span: 2 }}>
                                    <Form.Label>Number of Messages:</Form.Label>
                                </Col>
                                <Col sm={{ span: 1 }}>
                                    <Form.Select name="numMessages" value={controlOptions.numMessages} onChange={handleChange}>
                                        <option>30</option>
                                        <option>60</option>
                                        <option>90</option>
                                        <option>120</option>
                                    </Form.Select>
                                </Col>
                                <Col id="refresh-icon" sm={{ offset: 8 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Refresh Table
                                            </Tooltip>
                                        }
                                    >
                                        <Button id="refresh-icon-btn" onClick={refreshTable}>
                                            <ArrowClockwise style={{ cursor: "pointer" }} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row className='pt-10'>
                                <Col>
                                    <Form.Label>Facility/Group</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Search Period Start Date</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Search Period End Date</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Trigger Type(s)</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Select name="facility" value={controlOptions.facility} onChange={handleChange}>
                                        <option>--Select--</option>
                                        {facilityNames && (
                                            facilityNames.map((facility, index) => (
                                                <option key={index} value={facility.value}>{facility.label}</option>
                                            ))
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Control type="date" name="startDate"
                                        max={new Date().toISOString().split("T")[0]}
                                        value={controlOptions.startDate}
                                        onChange={handleChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="date" name="endDate"
                                        max={new Date().toISOString().split("T")[0]}
                                        value={controlOptions.endDate}
                                        onChange={handleChange} />
                                </Col>
                                <Col>
                                    <Select
                                        name="triggerTypes"
                                        isMulti
                                        options={triggerTypeOptions}
                                        hideSelectedOptions={false}
                                        isSearchable={true}
                                        styles={{
                                            menu: styles => ({ ...styles, zIndex: 999, fontSize: 13 }),
                                            valueContainer: styles => ({ ...styles, height: 30, width: 36 })
                                        }}
                                        onChange={handleSelectChange}
                                        closeMenuOnSelect={false}
                                    />
                                </Col>
                            </Row>
                            <Row className="pt-20">
                                <Col>
                                    <Form.Label>Field of Interest</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Messages to Find for Field</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Field of Interest (Secondary)</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label>Messages to Find for Field (Secondary)</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Select name="fieldOne" value={controlOptions.fieldOne} onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        {fieldOfInterestOptions.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select name="conformanceValueOne" value={controlOptions.conformanceValueOne} onChange={handleChange}>
                                        <option value="">--Select--</option>
                                        <option value="2">Find Conformant Messages</option>
                                        <option value="1">Find Non-Mapped/Coded Messages</option>
                                        <option value="0">Find Non-Populated Messages</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select name="fieldTwo" value={controlOptions.fieldTwo} onChange={handleChange}>
                                        <option value="">N/A</option>
                                        {fieldOfInterestOptions.map((option) => (
                                            <option value={option.value}>{option.label}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Select name="conformanceValueTwo" value={controlOptions.conformanceValueTwo} onChange={handleChange}>
                                        <option value="">N/A</option>
                                        <option value="2">Find Conformant Messages</option>
                                        <option value="1">Find Non-Mapped/Coded Messages</option>
                                        <option value="0">Find Non-Populated Messages</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="adt-fallout-table">
                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            icons={tableIcons}
                            columns={[
                                {
                                    title: 'Message Date',
                                    field: 'receipt_date',
                                    type: 'date',
                                },
                                {
                                    title: 'Hour Received by HIN',
                                    field: 'receipt_hour',
                                    type: 'int',
                                    render: rowData => {
                                        return hourToTime(rowData.receipt_hour);
                                    }
                                },
                                {
                                    title: 'Message Control ID (MSH-10)',
                                    field: 'message_control_id',
                                    type: 'string',
                                },
                                {
                                    title: 'Trigger Type',
                                    field: 'trigger_type',
                                    type: 'string',
                                }
                            ]}
                            data={tableData}
                            options={{
                                rowStyle: (data, index) => {
                                    if (index % 2) {
                                        return {
                                            backgroundColor: '#E5E9F1'
                                        };
                                    }
                                },
                                headerStyle: {
                                    backgroundColor: '#073271',
                                    color: 'white',
                                },
                                search: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 30, 60, 90, 120],
                                selection: true,
                                emptyRowsWhenPaging: false
                            }}
                            actions={[
                                {
                                    tooltip: 'Download all the selected ADT files.',
                                    icon: () => downloadIcon(),
                                    onClick: downloadFiles
                                }
                            ]}
                            title=""
                            localization={{
                                body: {
                                    emptyDataSourceMessage:
                                        (tableData && tableData.length > 0)
                                            ? 'Loading results...'
                                            : 'There are no results to show.',
                                },
                            }}
                        />
                    </MuiThemeProvider>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default ADTFalloutDashboard;
