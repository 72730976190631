import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReportModal from '../../components/modals/ReportModal';
import ADTFalloutDashboard from '../../components/dashboards/ADTFalloutDashboard';
import { Card, Container, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DownloadHelpIcon from '../../components/DownloadHelpIcon/DownloadHelpIcon';
import Loader from 'react-loader-spinner';
import './ADTReports.css'

const ADTReports = () => {

    const idToken = useSelector((state) => state.tokens.idToken);
    const accessFallout = useSelector((state) => state.tokens.falloutPermission);
    const [embedUrlFlag, setEmbedUrlFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isMountedRef = useRef(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (name, attributes) => {
        setModalName(name);
        setDashAttributes(attributes);
        setShow(true);
    }
    const [modalName, setModalName] = useState("");
    const [dashAttributes, setDashAttributes] = useState();

    const [showFallout, setShowFallout] = useState(false);

    // lower env's for testing
    const [adtDashboards, setAdtDashboards] = useState([
        { 'name': 'ADT Conformance Overview for Hospitals', 'id': '933a6e3f-a4e9-4412-97bf-1ada4470de88', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for organizations/groups that manage multiple Facilities, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
        { 'name': 'ADT Conformance Report for SNFs', 'id': '88392199-b055-4844-bd29-7101738e9247', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for Skilled Nursing Facilities (SNFs) and any organization/facility managing a Skilled Nursing Facility group, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
        { 'name': 'ADT Conformance Report for Hospitals', 'id': '10a2e26e-2b9b-4904-8d01-e1bc196d0b5a', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for an individual hospital, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
        { 'name': 'ADT Mapping Analysis', 'id': '1234', 'embedAttributes': '', 'description': 'Lists all values sent in a mapped segment over a given period and indicates whether or not the value was mapped.' },
        { 'name': 'ADT to MedRec Matching', 'id': '12345', 'embedAttributes': '', 'description': 'Shows percentage of A03 (discharge) notifications for which a corresponding C-CDA was sent within 24 hours.' }
    ]);

    //upper env dashboards
    // const [adtDashboards, setAdtDashboards] = useState([
    //     { 'name': 'ADT Conformance Overview for Hospitals', 'id': 'mi__adt_conformance_overview_for_hospitals__PREPROD', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for organizations/groups that manage multiple Facilities, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
    //     { 'name': 'ADT Conformance Report for SNFs', 'id': 'mi__adt_conformance_overview_for_snfs__PREPROD', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for Skilled Nursing Facilities (SNFs) and any organization/facility managing a Skilled Nursing Facility group, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
    //     { 'name': 'ADT Conformance Report for Hospitals', 'id': 'mi__adt_conformance_for_hospitals__PREPROD', 'embedAttributes': '', 'description': 'Provides an overview at the sender level of the Overall ADT Conformance Score for an individual hospital, along with the field score rates, such as population, mapping, correct coding, etc., of the various constituent fields.' },
    //     { 'name': 'ADT Mapping Analysis', 'id': '104b89de-e81e-4571-841b-bdf38185d556', 'embedAttributes': '', 'description': 'Lists all values sent in a mapped segment over a given period and indicates whether or not the value was mapped.' },
    //     { 'name': 'ADT to MedRec Matching', 'id': '776d670f-9d3b-4d16-8388-634e220b2b0f', 'embedAttributes': '', 'description': 'Shows percentage of A03 (discharge) notifications for which a corresponding C-CDA was sent within 24 hours.' }
    // ]);
    useEffect(() => {
        isMountedRef.current = true;
        if (embedUrlFlag) {
            setIsLoading(false);
        }
        return () => (isMountedRef.current = false);
    }, [embedUrlFlag]);

    useEffect(() => {
        isMountedRef.current = true;
        const getEmbedUrl = () => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('Authorization', idToken);
            adtDashboards.forEach((dashboard, index) => {
                let raw = JSON.stringify({
                    dashboardId: dashboard.id
                });
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                if (process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API) {
                    fetch(process.env.REACT_APP_CONFORMANCE_DASHBOARD_URL_API, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            const parsedResult = JSON.parse(result);
                            let tempList = [...adtDashboards];
                            tempList[index].embedAttributes = {
                                src: parsedResult.embedUrl,
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id
                            }

                            setAdtDashboards(tempList);
                            setEmbedUrlFlag(true)
                        })
                        .catch((error) => {
                            console.log("Error loading dashboards " + error);
                            setEmbedUrlFlag(true);

                            let tempList = [...adtDashboards];
                            tempList[index].embedAttributes = {
                                src: '',
                                width: '98%',
                                height: '660',
                                frameBorder: 1,
                                id: 'idIframe' + dashboard.id,
                            }

                            setAdtDashboards(tempList);
                        });
                }
            })
        };
        getEmbedUrl();
        return () => (isMountedRef.current = false);
    }, [idToken]);


    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        top: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#223c89',
                    }}
                >
                    <Loader type="ThreeDots" color="#223c89" height="80" width="80" />
                </div>
            )}
            {!isLoading &&
                <Container>
                    <h1>ADT Conformance Reports</h1>
                    <Row>
                        <Col className="text-center">
                            <h6 style={{ marginBottom: '16px' }}>Please select from the following report options:</h6>
                        </Col>
                    </Row>
                    <Row xs={1} md={2} className="g-4">
                        {adtDashboards.map((dashboard, index) => {
                            if ((dashboard.name === "ADT Fallout Report" || dashboard.name === "ADT Mapping Analysis") && accessFallout === "false") {
                                return "";
                            }
                            else {
                                if (dashboard.embedAttributes) {
                                    return (
                                        <Col className="item" key={index}>
                                            <OverlayTrigger
                                                key={index}
                                                placement="bottom"
                                                delay={{ hide: 100 }}
                                                overlay={
                                                    <Tooltip id={index}>
                                                        {dashboard.description}
                                                    </Tooltip>
                                                }
                                            >
                                                <Card>
                                                    <Card.Header as="h5" className="text-center" onClick={() => handleShow(dashboard.name, dashboard.embedAttributes)}>{dashboard.name}</Card.Header>
                                                </Card>
                                            </OverlayTrigger>
                                        </Col>
                                    )
                                }
                            }
                            return "";
                        })}
                        <Col>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ hide: 100 }}
                                overlay={
                                    <Tooltip>
                                        Uses the filters to find messages within the last 30 days that meet up to four separate ADT conformance criteria.
                                    </Tooltip>
                                }
                            >
                                <Card>
                                    <Card.Header as="h5" className="text-center" onClick={() => setShowFallout(true)}>ADT Fallout Report</Card.Header>
                                </Card>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Container>
            }
            <Modal show={show} onHide={handleClose} fullscreen className="reportmodal">
                <ReportModal modalName={modalName} dashAttributes={dashAttributes} />
            </Modal>
            {accessFallout &&
                <>
                    <Modal show={showFallout} onHide={() => setShowFallout(false)} fullscreen>
                        <Modal.Header closeButton>
                            <Modal.Title>ADT Fallout Report </Modal.Title>
                            <DownloadHelpIcon />
                        </Modal.Header>
                        <Modal.Body>
                            <ADTFalloutDashboard />
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    );
};

export default ADTReports;
